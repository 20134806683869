<template>
  <b-card header="Tools">
    <b-row cols-sm="1" cols-md="2"  cols-lg="3" no-gutters>
        <b-col sm v-for="(tool, index) in tools" :key="index">
            <router-link :to="tool.to" class="text-decoration-none text-dark">
                <div class="tool-menu border rounded p-3 text-center m-2">
                    <div class="my-3">
                        <i :class="`${tool.icon} fa-5x`"></i>
                    </div>
                    <h4>{{ tool.name }}</h4>
                </div>
            </router-link>
        </b-col>
    </b-row>
  </b-card>
</template>

<script>
import navs from '../container/_nav'
export default {
  data(){
    return {
      tools:  navs.filter(nav => !nav.title && nav.to !== "/dashboard")
    }
  }
}
</script>

<style scoped>
  .tool-menu:hover{
    box-shadow: 4px 5px 13px -5px rgba(0,0,0,0.63);
    -webkit-box-shadow: 4px 5px 13px -5px rgba(0,0,0,0.63);
    -moz-box-shadow: 4px 5px 13px -5px rgba(0,0,0,0.63);
  }
</style>